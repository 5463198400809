export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/gyros-logo-for-web.png',
    banner_img_root : '/img/world-cafe-banner-2.png',
    resid : '415c99a2f768b8ace19f3324051b72bc',
    key_value : 'gyrostowncatering',
    secret_value : 'gyrostowncatering',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3070.863083085556!2d-104.94242008520975!3d39.675294379458265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c7e083f4cca47%3A0xce8994bb23492f0e!2s2276%20S%20Colorado%20Blvd%2C%20Denver%2C%20CO%2080222%2C%20USA!5e0!3m2!1sen!2sin!4v1577271504618!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Gyros Town"
};
